import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { device } from "../base/device"
import backgroundImage from "../images/house.jpg"

const Hero = styled.div`
  background-image: url(${backgroundImage});
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  height: 100vh;
  padding: 4rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #231c10;
    opacity: 0.5;
  }

  h1 {
    margin: 100px auto 0;
    color: #fff;
    text-align: center;
    font-weight: 300;
    max-width: 680px;
    position: relative;
    z-index: 2;
    font-size: 30px;

    @media ${device.medium} {
      font-size: 50px;
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Hero>
      <h1>Page not found</h1>
    </Hero>
  </Layout>
)

export default NotFoundPage
